import { motion } from "framer-motion";
import "./styles.scss";
import Navbar from "./components/Navbar/navbar.component";
import Section from "./components/Section/section.component";
import Experience from "./components/Experience/experience.component";

export default function App() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.4,
      },
    },
  };

  const Variant = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="App">
      <motion.div
        className="cards"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div variants={Variant}>
          <Navbar />
        </motion.div>
        <motion.div variants={Variant}>
          <Section />
        </motion.div>
        <motion.div variants={Variant}>
          <Experience />
        </motion.div>
      </motion.div>
    </div>
  );
}
