import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "./App";
import Career from "./components/Pages/Career";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/career",
    element: <Career />,
  },
]);

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <RouterProvider router={router}></RouterProvider>
  </StrictMode>,
  rootElement
);
