import React, { useState, useEffect } from "react";

const Section = () => {
  return (
    <div className="hero caveat">
      <h1>Hello, I’m Steeb.</h1>
      <h2>
        A Software Engineer with a passion for modern web, startups,
        infrastructure and architecture. I love keeping on top of bleeding edge
        technology and enjoy working with people who are passionate about
        technology.
      </h2>
      <h3>↓</h3>
    </div>
  );
};

export default Section;
