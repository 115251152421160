import React, { useState } from "react";
import { motion } from "framer-motion";
import { jobs } from "./jobs";
import "./styles.scss";

const Career = () => {
  //State to determine which job description is shown
  const [selectedJob, setSelectedJob] = useState(null);

  const handleClick = (id) => {
    setSelectedJob(selectedJob === id ? null : id);
  };

  const variants = {
    hover: { backgroundColor: "rgb(221, 226, 230)" },
    rest: { backgroundColor: "#f3f3f3" },
    tap: { backgroundColor: "#f3f3f3", transition: { duration: 0.2 } },
  };

  return (
    <div className="career">
      <h1>Career</h1>
      <div className="jobs">
        {jobs.map((job) => (
          <motion.div
            className="job"
            key={job.id}
            onClick={() => handleClick(job.id)}
            variants={variants}
            initial="rest"
            whileHover="hover"
            whileTap="tap"
            animate="rest"
          >
            <h3>{job.title}</h3>
            {selectedJob === job.id && (
              <>
                {job.responsibilities.map((responsibility) => (
                  <p>{responsibility}</p>
                ))}
                <p>{job.description}</p>
                <p>{job.description}</p>
                <p>{job.description}</p>
              </>
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Career;
