import React, { useState, useEffect } from "react";
import axios from "axios";

const Navbar = () => {
  const url =
    "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd&include_24hr_change=true";
  const [btcPrice, setbtcPrice] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url);
        setbtcPrice(response?.data?.bitcoin?.usd);
      } catch (error) {
        console.error("Error fetching BTC price:", error);
      }
    };

    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 1200000); // Fetch every 30 seconds
    return () => {
      clearInterval(interval); // Clean up interval on component unmount
    };
  }, [url]);

  return (
    <nav>
      <img
        className="logo-image"
        src="./images/autumn.png"
        alt=""
        height="50px"
        width="50px"
      />
      <div className="menu">
        {btcPrice ? <div className="btcusd">BTC:{btcPrice}</div> : ""}
      </div>
    </nav>
  );
};

export default Navbar;
