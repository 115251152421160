export const jobs = [
  {
    id: 1,
    title: "Junior Frontend Engineer",
    description: "Job 1 description",
    jobTitle: "Junior React Native Developer (Self-Study Enthusiast)",
    company: "X",
    location: "Bnagalore - Hybrid",
    jobType: "Contract",
    aboutUs:
      "[Your Company Name] is an exciting early-stage startup with a mission to revolutionize [describe your startup's mission or industry]. We're in the process of building our first prototype, and we're seeking a highly motivated and passionate Junior React Native Developer who is not only a self-starter but also has a strong desire to learn and grow in the world of web and mobile development.",
    jobDescription:
      "Are you a self-study enthusiast with a burning passion for React and React Native? Are you excited about the opportunity to play a pivotal role in building a groundbreaking prototype from the ground up? If so, we want to hear from you! As a Junior React Native Developer at [Your Company Name], you will have the unique chance to contribute to our early-stage journey and shape the future of our product.",
    responsibilities: [
      "Take the initiative to learn and become proficient in React and React Native through self-study.",
      "Collaborate with the team to design, develop, and maintain mobile applications using React Native.",
      "Assist in the development of web applications using React.",
      "Participate in debugging, testing, and troubleshooting code to ensure optimal performance.",
      "Contribute to the design and implementation of user interfaces.",
      "Share your enthusiasm and passion for self-improvement and web development with the team.",
    ],
    qualifications: [
      "A strong desire and commitment to self-study and learning React and React Native.",
      "Eagerness to take ownership of your personal development in the field of web and mobile development.",
      "Ability to work collaboratively, learn from others, and contribute to a team effort.",
      "Excellent communication and problem-solving skills.",
      "A genuine passion for web development and a desire to make an impact on an early-stage startup.",
    ],
    niceToHave: [
      "Basic knowledge of web development technologies, such as HTML, CSS, JavaScript, and front-end libraries.",
      "Familiarity with version control systems like Git.",
    ],
    commitmentToNDA:
      "As we are an early-stage startup working on a groundbreaking prototype, we require all team members to commit to a Non-Disclosure Agreement (NDA) to protect our intellectual property, confidential information, and trade secrets. We take the security of our project very seriously, and we expect all team members to uphold the highest standards of confidentiality and professionalism.",
    whatWeOffer: [
      "A unique opportunity to be an integral part of an early-stage startup and play a crucial role in building a prototype.",
      "A dynamic and supportive work environment where your dedication to self-study is encouraged and valued.",
      "Mentorship and guidance from experienced developers.",
      "Competitive salary and the potential for equity in the company as we grow.",
    ],
    howToApply:
      "To join us in shaping the future of our exciting startup, please submit your resume, a brief cover letter detailing your commitment to self-study, your interest in the role, and any relevant personal projects or experiences to [email address].",
  },
  {
    id: 2,
    title: "Senior Backend Engineer",
    description: "Job 2 description",
  },
];
