import React from "react";
import { motion } from "framer-motion";

const Experience = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        // This staggerChildren is currently not effective to animate content under Experience.
        //Change it to 0.9 to see the effect. First 2 blocks render at the same time.
        //Also, the blocks take exponentially longer to stagger probably due to the stagger from parent.
      },
    },
  };

  const cVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="experience">
      <div className="exp-header caveat">Work</div>
      <motion.div
        className="cards"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="exp-rows">
          <motion.div variants={cVariants}>
            <section className="exp-content">
              <p className="exp timeline">July 2022 - Present:</p>
              <div className="employericon" text="fintech">
                💰
              </div>
              <div className="exp description">
                <p className="employer">Laybuy, NZ ⇒ Software Engineer</p>
                <p>Frontend Consolidation Project, Infrastructure</p>
                <p>Architecture</p>
                <p>
                  Tools: Javascript/Typescript, React, React-native, Vite,
                  Ionic, URQL, Zustand, GraphQL, Node, K8s, AWS, Pulumi, Github
                  Workflows, Appflow
                </p>
              </div>
            </section>
          </motion.div>
          <motion.div variants={cVariants}>
            <section className="exp-content">
              <p className="exp timeline">July 2021 - July 2022:</p>
              <div className="employericon" text="fintech">
                💰
              </div>
              <div className="exp description">
                <p className="employer">
                  Laybuy, NZ ⇒ Software Engineer in Test » QA Lead
                </p>
                <p>
                  Tests, Performance, Infrastructure. Automation Testing,
                  Process Improvements, CI/CD
                </p>
                <p>Architecture, Team Building</p>
                <p>
                  Tools: React, Jest, Cypress, Docker, Kubernetes, Vitest,
                  Cypress, Ionic, React, Capacitor, React Testing Library, AWS,
                  Pulumi
                </p>
              </div>
            </section>
          </motion.div>
          {/* <motion.div variants={cVariants}>
            <section className="exp-content">
              <p className="exp timeline">July 2021 - Mar 2022:</p>
              <div className="employericon" text="fintech">
                💰
              </div>
              <div className="exp description">
                <p className="employer">
                  Laybuy, NZ ⇒ Software Engineer in Test
                </p>
                <p></p>
                <p></p>
                <p>Tools: React, Jest, Cypress, Docker, Kubernetes</p>
              </div>
            </section>
          </motion.div> */}
          <motion.div variants={cVariants}>
            <section>
              <p className="exp timeline">Dec 2019 - July 2021:</p>
              <div className="employericon" text="fintech">
                🐶
              </div>
              <div className="exp description">
                <p className="employer">ezyVet, NZ ⇒ QA Engineer » QA Lead</p>
                <p>Automation and Manual Testing, API Testing, Team building</p>
                <p>Tools: JS, Cypress, Gitlab, AWS</p>
              </div>
            </section>
          </motion.div>
          <motion.div variants={cVariants}>
            <section>
              <p className="exp timeline">June 2016 - July 2018:</p>
              <div className="employericon" text="fintech">
                👕
              </div>
              <div className="exp description">
                <p className="employer">
                  Nordstrom, IN ⇒ Software Engineer in Test
                </p>
                <p>Automation Testing, Project Test Lead</p>
                <p>Manual Testing, Unit Testing</p>
                <p>
                  Tools: Nightwatch.js, Watir, BrowserStack, Google analytics,
                  Tealium
                </p>
              </div>
            </section>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default Experience;
